import { defineStore } from 'pinia'

export const useChatStore = defineStore(
  "ChatStore",
  () => {
    const history = ref({
      default: []
    })
    const tosAccepted = ref(false)

    const setHistory = (h: any, team: string) => {
      history.value[team] = h
    }

    const acceptTos = () => {
      tosAccepted.value = true
    }
  
      return {
        history,
        setHistory,
        tosAccepted,
        acceptTos
      }
    }, {
      persist:  true
  }
)